<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model[modelName].modelName"
      :label="$store.state.stone_model[modelName].label"
      :fields="$store.state.stone_model[modelName].fields"
      :liveSearching="true"
      :pageMode="true"
      :showFields="showFields"
      :expandable="false"
      :showExpand="false"
      :infiniteScroll="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$store.state.stone_model[this.modelName].label,
    };
  },
  data: () => ({
    order: "last_order_no",
    modelName: "ws_blog",
    showFields: ["id", "title", "tags", "ws_blog_classes"],
  }),
};
</script>